import { useParams } from 'react-router-dom';
import GerentedeProjectos from '../layout/vagas/GerentedeProjectos.js';
import RepresentantedeVendas from '../layout/vagas/RepresentantedeVendas.js';
import FieldeArmazem from '../layout/vagas/FieldeArmazem.js';
import ChiefFinancialOfficer from '../layout/vagas/ChiefFinancialOfficer.js';
import KeyAccountManager from '../layout/vagas/KeyAccountManager.js';
import ProcurementOfficer from '../layout/vagas/ProcurementOfficer.js';

function VagaDescription() {
  const { titulo } = useParams();

  // Função para exibir o componente correto com base no título
  const renderComponenteVaga = () => {
    switch (decodeURIComponent(titulo).toLowerCase()) {
      case 'gerente de projetos':
        return <GerentedeProjectos />;
      case 'representante de vendas':
        return <RepresentantedeVendas />;
      case 'fiel de armazém':
        return <FieldeArmazem />;
      case 'chief financial officer (cfo)':
        return <ChiefFinancialOfficer />;
      case 'key account manager':
        return <KeyAccountManager />;
        case 'procurement officer':
        return <ProcurementOfficer />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderComponenteVaga()}
    </>
  );
}

export default VagaDescription;
import FielInfoForm from '../layout/FielInfoForm.js';

function CandidaturasPersonal() {
  return (
    <div>
         <FielInfoForm />   
             
    </div>
  )
}

export default CandidaturasPersonal
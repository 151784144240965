import emailjs from '@emailjs/browser';
import { useCallback, useEffect, useRef, useState } from 'react';
import img1 from '../../assets/2-2.png';
import img2 from '../../assets/6-1.png';
import styles from './SlideScrol.module.css';

function SlideScrol() {
  const images = [img1, img2];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images.length]);

  const prevImage = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, [images.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      nextImage();
    }, 3000);
    return () => clearInterval(timer);
  }, [nextImage]);

  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => setIsFormVisible(true), 100);
  };

  const closeModal = () => {
    setIsFormVisible(false);
    
    setTimeout(() => setIsModalOpen(false), 300);
  };
  const form = useRef();

    const sendEmail = (e) =>{
        e.preventDefault();

        emailjs.sendForm('service_vtszeyk','template_5mukite',form.current,{
            publicKey : 'N5EwAGl8qTGFqxjXX'
        })
        .then(() =>{
            alert('Mensagem enviada com sucesso');
        },(err) =>{
            alert('Falha ao mandar mensagem :'+err);
        });
    };

  return (
    <div className={styles.sliderContainer}>
      <button className={`${styles.navButton} ${styles.prevButton}`} onClick={prevImage}>
        ‹
      </button>
      <div className={styles.imageContainer}>
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Slide ${index + 1}`}
            style={{ opacity: index === currentIndex ? 1 : 0 }}
          />
        ))}
        <button className={styles.btn} onClick={openModal}>
          Agende uma Consulta
        </button>
      </div>
      <button className={`${styles.navButton} ${styles.nextButton}`} onClick={nextImage}>
        ›
      </button>
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div 
            className={`${styles.modalContent} ${isFormVisible ? styles.modalVisible : ''}`}
            onClick={e => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeModal}>
              <span className={styles.closeIcon}>×</span>
            </button>
            <div className={styles.modalHeader}>
              <h2>Agende uma Consulta</h2>
              <p>Preencha o formulário abaixo para agendar sua consulta</p>
            </div>
            <form ref={form} onSubmit={sendEmail} className={styles.form_agendamento}>
              <div className={styles.formGroup}>
                <label htmlFor="nome">Nome Completo</label>
                <input type="text" id="nome" name="user_name" required />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" required />
              </div>

              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="telefone">Telefone</label>
                  <input type="tel" id="telefone" name="user_tel" required />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="servico">Serviço Desejado</label>
                  <select id="servico" name="user_service" required>
                    <option value="">Selecione</option>
                    <option value="Assessment">Assessment</option>
                    <option value="Coaching Executivo">Coaching Executivo</option>
                    <option value="Formação e Desenvolvimento">Formação e Desenvolvimento</option>
                  </select>
                </div>
              </div>

              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="data">Data Preferida</label>
                  <input type="date" id="data" name="user_data" required />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="horario">Horário Preferido</label>
                  <input type="time" id="horario" name="user_horario" required />
                </div>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="mensagem">Observações</label>
                <textarea id="mensagem" name="message" rows="4"></textarea>
              </div>

              <div className={styles.formGroup}>
                <label className={styles.checkboxLabel}>
                  <input type="checkbox" id="consentimento" required />
                  <span>Eu aceito a política de privacidade</span>
                </label>
              </div>

              <button type="submit" className={styles.submitButton}>
                Enviar Agendamento
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default SlideScrol;
import PersonalInfoForm from '../layout/PersonalInfoForm.js'
import style from './Contactos.module.css'

function Candidaturas() {
  return (
    <div className={style.vagas_constainer}>
      
      <PersonalInfoForm />

    </div>
  )
}

export default Candidaturas
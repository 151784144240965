import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './PersonalInfoForm.module.css';

function PersonalInfoForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    position: '',
    firstName: '',
    lastName: '',
    age: '',
    nationality: '',
    country: '',
    province: '',
    phone: '',
    email: '',
    linkedin: '',
    mobility: ''
  });

  const [errors, setErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }


  };

  useEffect(() => {
    let timer;
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showMessage]);

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    Object.keys(formData).forEach(key => {
      // Skip LinkedIn as it's optional
      if (key !== 'linkedin' && !formData[key]) {
        newErrors[key] = 'Este campo é obrigatório';
      }
    });

    // Email validation
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email inválido';
    }

    // Phone validation (assuming Mozambique format)
    if (formData.phone && !/^[8][2-7][0-9]{7}$/.test(formData.phone)) {
      newErrors.phone = 'Número de telefone inválido';
    }

    // Age validation
    if (formData.age) {
      const age = parseInt(formData.age);
      if (isNaN(age) || age < 18 || age > 100) {
        newErrors.age = 'Idade inválida';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {

      localStorage.setItem('formData', JSON.stringify(formData));
      navigate('/candidaturaspersonal');

    } else {
      setShowMessage(true);
    }
  };

  const getInputClassName = (fieldName) => {
    return `${style.input} ${errors[fieldName] ? style.errorInput : ''}`;
  };

  const getPositionDescription = () => {
    switch (formData.position) {
      case 'vaga2':
        return (
          <>
            <h3 className={style.vagaTitle}>Chief Financial Officer (CFO)</h3>
            <p>Welcome to the application process for the Chief Financial Officer (CFO) position at a leading company in the FMCG, Distribution, and Retail sector and for an NGO based in Maputo.</p>
            <p>This form is designed to gather detailed information about your experience and skills essential for the role, including your familiarity with financial tools and systems.</p>
            <p>We appreciate your interest and the time you dedicate to this application.</p> <br></br>
            <p><i>*Only shortlisted candidates will be contacted.</i></p>

          </>
        );
      case 'vaga1':
        return (
          <>
            <h3 className={style.vagaTitle}>Candidatura para a vaga de Fiel de Armazém</h3>
            <p>Estamos a contratar um(a) Fiel de Armazém para uma empresa que actua no sector de distribuição, localizada em Pemba.</p>
            <p>Se tens experiência em gestão de armazéns e desejas integrar uma equipa dinâmica, preenche o formulário abaixo para te candidatar.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );
      case 'vaga3':
        return (
          <>
            <h3 className={style.vagaTitle}>Procurement Junior</h3>
            <p>Estamos contratando um(a) Procurement Junior para ajudar na aquisição de bens e serviços para uma empresa em Maputo. É necessário ter noções básicas de cadeia de suprimentos e habilidades de negociação.</p>
            <p>Preencha o formulário para se candidatar a esta posição.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga4':
        return (
          <>
            <h3 className={style.vagaTitle}>Representante De Vendas Sénior</h3>
            <p>Estamos buscando um Representante de Vendas Sénior para liderar iniciativas de vendas em uma empresa do sector de FMCG, com sede em Maputo.</p>
            <p>Se possui experiência em vendas e liderança de equipes, candidate-se preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga5':
        return (
          <>
            <h3 className={style.vagaTitle}>Representante de Vendas</h3>
            <p>Procuramos um Representante de Vendas para actuar na linha de frente, promovendo nossos produtos e estabelecendo conexões com os clientes.</p>
            <p>Se é comunicativo e gosta de trabalhar em vendas, esta vaga é para você. Candidate-se preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga6':
        return (
          <>
            <h3 className={style.vagaTitle}>Analista de Logística</h3>
            <p>Estamos à procura de um Analista de Logística para otimizar o fluxo de produtos e garantir a eficiência operacional.</p>
            <p>Caso tenha experiência em logística e transporte, inscreva-se para esta oportunidade preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga7':
        return (
          <>
            <h3 className={style.vagaTitle}>Gestor de Projetos de Construção</h3>
            <p>Procuramos um Gestor de Projectos de Construção experiente para liderar nossos projetos em diferentes locais de Moçambique.</p>
            <p>Se possui experiência em gestão de projectos de construção, candidate-se preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga8':
        return (
          <>
            <h3 className={style.vagaTitle}>Analista de Sistemas Sénior</h3>
            <p>Estamos em busca de um Analista de Sistemas Sénior para desenvolver e optimizar sistemas para suportar operações empresariais complexas.</p>
            <p>Caso tenha vasta experiência em análise de sistemas, preencha o formulário abaixo para se candidatar.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga9':
        return (
          <>
            <h3 className={style.vagaTitle}>Engenheiro Sénior de Telecomunicações</h3>
            <p>Procuramos um Engenheiro Sénior de Telecomunicações para supervisionar projectos de infraestrutura e manutenção de rede.</p>
            <p>Se possui experiência avançada na área, inscreva-se para esta posição preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga10':
        return (
          <>
            <h3 className={style.vagaTitle}>Especialista em Marketing Digital</h3>
            <p>Estamos buscando um Especialista em Marketing Digital para criar e gerenciar campanhas de marketing online que aumentem o engajamento com nossos clientes.</p>
            <p>Se possui experiência em marketing digital, candidate-se preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga11':
        return (
          <>
            <h3 className={style.vagaTitle}>Estagiário de Contabilidade</h3>
            <p>Procuramos um Estagiário de Contabilidade para apoiar as operações financeiras e administrativas da empresa.</p>
            <p>Se está em início de carreira na área de contabilidade, inscreva-se para esta oportunidade preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );
      case 'vaga12':
        return (
          <>
            <h3 className={style.vagaTitle}>Gestor de Produção de Enchidos e de Carroçarias</h3>
            <p>Estamos contratando um Gestor de Produção para supervisionar a fabricação de enchidos e carroçarias, garantindo eficiência e qualidade no processo produtivo.</p>
            <p>Se possui experiência em gestão de produção, especialmente em indústrias alimentícias e de carroçarias, preencha o formulário abaixo para se candidatar.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga13':
        return (
          <>
            <h3 className={style.vagaTitle}>Merchandiser</h3>
            <p>Procuramos um Merchandiser para apoiar na exposição de produtos em pontos de venda e maximizar o impacto visual de nossas mercadorias.</p>
            <p>Se tem experiência em merchandising e boas habilidades de organização, candidate-se preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga14':
        return (
          <>
            <h3 className={style.vagaTitle}>Fiel de Armazém</h3>
            <p>Estamos à procura de um Fiel de Armazém para gerenciar o controle de estoque e organização de mercadorias.</p>
            <p>Se tem experiência em logística e armazéns, inscreva-se para esta oportunidade preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga15':
        return (
          <>
            <h3 className={style.vagaTitle}>Assistente de Vendas</h3>
            <p>Procuramos um Assistente de Vendas para dar suporte à equipa comercial em atividades de vendas e atendimento ao cliente.</p>
            <p>Se é comunicativo e gosta de lidar com clientes, inscreva-se para esta posição preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga16':
        return (
          <>
            <h3 className={style.vagaTitle}>Repositor</h3>
            <p>Estamos à procura de um Repositor para garantir que nossos produtos estejam sempre disponíveis e organizados nas prateleiras das lojas.</p>
            <p>Se é proactivo e gosta de trabalhar em um ambiente dinâmico, candidate-se preenchendo o formulário.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga17':
        return (
          <>
            <h3 className={style.vagaTitle}>Talheiro</h3>
            <p>Estamos contratando um Talheiro para trabalhar em um ambiente de produção de carne, com foco na preparação e corte de carnes.</p>
            <p>Se possui experiência em talho e cortes de carnes, preencha o formulário abaixo para se candidatar.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga18':
        return (
          <>
            <h3 className={style.vagaTitle}>Empacotador</h3>
            <p>Procuramos um Empacotador para auxiliar no processo de embalagem e preparação de produtos para distribuição.</p>
            <p>Se é ágil e gosta de trabalho manual, inscreva-se para esta vaga preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga19':
        return (
          <>
            <h3 className={style.vagaTitle}>Técnico de Inventário</h3>
            <p>Estamos à procura de um Técnico de Inventário para realizar o controle e contagem de estoque, garantindo precisão nos registos.</p>
            <p>Se possui experiência em inventário e controle de estoque, candidate-se preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga20':
        return (
          <>
            <h3 className={style.vagaTitle}>Organizador de Espaços de Vendas</h3>
            <p>Procuramos um Organizador de Espaços de Vendas para optimizar a disposição de produtos e melhorar a experiência do cliente em nossos pontos de venda.</p>
            <p>Se tem boas habilidades de organização e visual merchandising, preencha o formulário para se candidatar.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      case 'vaga21':
        return (
          <>
            <h3 className={style.vagaTitle}>Contabilista Sénior - Nacala</h3>
            <p>Estamos contratando um Contabilista Sénior para nossa unidade em Nacala, responsável por gerenciar actividades contábeis e relatórios financeiros.</p>
            <p>Se possui experiência em contabilidade avançada, candidate-se preenchendo o formulário abaixo.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );
        case 'vaga22':
        return (
          <>
            <h3 className={style.vagaTitle}>Procurement Officer</h3>
            <p>A Pathway Consulting está a recrutar um(a) Procurement Officer para uma empresa de referência no sector de Oil and Gas, localizada na Cidade da Matola. O(a) profissional será responsável por apoiar na gestão de todas as actividades relacionadas com o processo de aquisição de bens, serviços e materiais, garantindo que os processos estejam em conformidade com as políticas e normas de procurement, e que as necessidades da organização sejam atendidas de forma eficiente e económica.</p>
            <p><i>*Apenas os candidatos pré-selecionados serão eventualmente contactados.</i></p>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={style.formContainer}>
      <h1 className={style.title}>Candidaturas Pathway</h1>

      {showMessage && (
        <div className={style.errorMessage}>
          Por favor, preencha todos os campos obrigatórios corretamente
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <label className={style.label}>
          Selecione a vaga (select your application position) *
          <select name="position" value={formData.position} onChange={handleChange} required>
            <option value="">Please Select</option>
            <option value="vaga1">Fiel de Armazém</option>
            <option value="vaga2">Chief Financial Officer (CFO)</option>
            <option value="vaga3">Procurement Junior</option>
            <option value="vaga4">Representante De Vendas Sénior</option>
            <option value="vaga5">Representante de vendas</option>
            <option value="vaga6">Analista de logística</option>
            <option value="vaga7">Gestor do projectos de construção</option>
            <option value="vaga8">Analista De Sistemas Sénior</option>
            <option value="vaga9">Engenheiro Sénior de Telecomunicações</option>
            <option value="vaga10">Especialista Em Marketing Digital</option>
            <option value="vaga11">Estagiário De Contabilidade</option>
            <option value="vaga12">Gestor de produção de enchidos e de carroçarias</option>
            <option value="vaga13">Merchandiser</option>
            <option value="vaga14">Fiel de Armazém</option>
            <option value="vaga15">Assistente de Vendas</option>
            <option value="vaga16">Repositor</option>
            <option value="vaga17">Talheiro</option>
            <option value="vaga18">Empacotador</option>
            <option value="vaga19">Técnico de Inventário</option>
            <option value="vaga20">Organizador de Espaços de Vendas</option>
            <option value="vaga21">Contabilista Sénior - Nacala</option>
            <option value="vaga22">Procurement Officer</option>

          </select>
          {errors.position && <span className={style.errorText}>{errors.position}</span>}
        </label>

        {/* Exibir a descrição da vaga selecionada */}
        <div className={style.vagaDescription}>
          {getPositionDescription()}
        </div>

        {/* Restante do formulário */}
        <div className={style.row}>
          <label className={style.label}>
            Nome completo *
            <input type="text" name="firstName" placeholder="Primeiro nome" value={formData.firstName} onChange={handleChange} className={getInputClassName('firstName')} />
            {errors.firstName && <span className={style.errorText}>{errors.firstName}</span>}
          </label>

          <label className={style.label}>
            Apelido *
            <input type="text" name="lastName" placeholder="Apelido" value={formData.lastName} onChange={handleChange} className={getInputClassName('lastName')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.lastName}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            Idade *
            <input type="number" name="age" placeholder="Ex: 23" value={formData.age} onChange={handleChange} className={getInputClassName('age')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.age}</span>}
          </label>

          <label className={style.label}>
            Nacionalidade *
            <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} className={getInputClassName('nationality')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.nationality}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            País de residência *
            <input type="text" name="country" value={formData.country} onChange={handleChange} className={getInputClassName('country')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.country}</span>}
          </label>

          <label className={style.label}>
            Província de residência *
            <input type="text" name="province" value={formData.province} onChange={handleChange} className={getInputClassName('province')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.province}</span>}
          </label>
        </div>

        <div className={style.row}>
          <label className={style.label}>
            Contacto telefónico *
            <input type="tel" name="phone" placeholder="Ex: 828384858" value={formData.phone} onChange={handleChange} className={getInputClassName('phone')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.phone}</span>}
          </label>

          <label className={style.label}>
            Endereço de email *
            <input type="email" name="email" placeholder="example@example.com" value={formData.email} onChange={handleChange} className={getInputClassName('email')}
            />
            {errors.lastName && <span className={style.errorText}>{errors.email}</span>}
          </label>
        </div>

        <label className={style.label}>
          LinkedIn (opcional)
          <input type="url" name="linkedin" placeholder="LinkedIn profile URL" value={formData.linkedin} onChange={handleChange} />
        </label>

        <fieldset className={style.checkboxGroup}>
          <legend>Disponibilidade para trabalhar fora da província de residência (mobilidade)</legend>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="sim" onChange={handleChange} required /> Sim
          </label>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="nao" onChange={handleChange} required /> Não
          </label>
          <label className={style.checkboxLabel}>
            <input type="radio" name="mobility" value="ocasional" onChange={handleChange} required /> Disponível para deslocações ocasionais
          </label>
        </fieldset>

        <button type="submit" className={style.submitButton}>
          Next
        </button>
      </form>
    </div>
  );
}

export default PersonalInfoForm;

import { Link } from 'react-router-dom'
import style from './NossosServicos.module.css'

function NossosServicos() {
    return (
        <section id="services" className={style.services}>

            <div className={style.container}>
                <Link to='/servicos' className={style.title}>Nossos Serviços</Link>
                <div className={style.services_grid}>
    <div className={style.service_card}>
        <h3>Consultoria de RH</h3>
        <p>
            Oferecemos consultoria estratégica para optimizar todos os processos de Recursos Humanos da sua empresa, 
            desde o recrutamento e seleção até a gestão de desempenho e retenção de talentos...
        </p>
    </div>
    <div className={style.service_card}>
        <h3>Formação e Desenvolvimento</h3>
        <p>
            Nossos programas de formação são projectados para desenvolver as habilidades técnicas e comportamentais da sua equipa, alinhando o crescimento profissional com as metas organizacionais...
        </p>
    </div>
    <div className={style.service_card}>
        <h3>Captação e Seleção de Talentos</h3>
        <p>
            Identificamos, atraímos e selecionamos os melhores profissionais para sua empresa, utilizando métodos avançados de recrutamento e uma abordagem personalizada...
        </p>
    </div>
    <div className={style.service_card}>
        <h3>Gestão de Talentos</h3>
        <p>
            Implementamos estratégias de gestão de talentos que promovem o desenvolvimento e a retenção dos seus colaboradores mais valiosos. Oferecemos suporte para criar planos de carreira, avaliar o desempenho...
        </p>
    </div>
</div>

            </div>

        </section>

    )
}

export default NossosServicos
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/layout/Navbar.js';
import Contactos from './components/pages/Contactos.js';
import Inicio from './components/pages/Inicio.js';
import Servicos from './components/pages/Servicos.jsx';
import Sobre from './components/pages/Sobre.js';
import Vagas from './components/pages/Vagas.js';
import Candidaturas from './components/pages/Candidaturas.js';
import CandidaturasPersonal from './components/pages/CandidaturasPersonal.js';
import Footer from './components/pages/Footer.jsx';
import VagaDescription from './components/pages/VagaDescription.js';
import CandidatarPersonalForm from './components/layout/CandidatarPersonalForm.js';




function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path='/servicos' element={<Servicos  />} />
          <Route path='/sobre' element={<Sobre />} />
          <Route path='/vagas' element={<Vagas />} />
          <Route path='/contactos' element={<Contactos />} />
          <Route path='/candidaturas' element={<Candidaturas />} />
          <Route path='/candidaturaspersonal' element={<CandidaturasPersonal />} />
          <Route path='/descricaovaga/:titulo' element={<VagaDescription />} />
          <Route path='/candidaturaspersonal/:titulo' element={<CandidatarPersonalForm />} />
          
        </Routes>

        <Footer />
      </Router>

    </div>
  );
}

export default App;

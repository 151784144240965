import NossosServicos from '../layout/NossosServicos.js'
import QuemSomos from '../layout/QuemSomos.js'
import SlideScrol from '../layout/SlideScrol.js'
// import Testemunhos from '../layout/Testemunhos.js'
import VagasComponent from '../layout/VagasComponent.js'
import style from './Inicio.module.css'

function Inicio() {
  return (
    <div className={style.inicio_constainer}>
      <SlideScrol />
      <QuemSomos />
      <NossosServicos />
      {/* <Testemunhos /> */}
      <VagasComponent />

    </div>
  )
}

export default Inicio
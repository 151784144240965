import style from './KeyAccountManager.module.css';
import img from '../../../assets/procurement.png'
import { Link } from 'react-router-dom';

function ProcurementOfficer() {

  const imgg = img
  const titulo = 'Procurement Officer';
  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Procurement Officer</h1>
          <div className={style.jobDescription}>
            <p>
              A Pathway Consulting está a recrutar um(a) Procurement Officer para uma empresa de referência no sector de Oil and Gas, localizada na Cidade da Matola. O(a) profissional será responsável por apoiar na gestão de todas as actividades relacionadas com o processo de aquisição de bens, serviços e materiais, garantindo que os processos estejam em conformidade com as políticas e normas de procurement, e que as necessidades da organização sejam atendidas de forma eficiente e económica.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Apoiar na preparação e implementação do plano de procurement, assegurando que sejam seguidas as normas e procedimentos estabelecidos.</li>
          <li>Apoiar o Sénior de Procurement na gestão dos processos de aquisição, incluindo pedidos de cotação, envio de cotações e follow-up de processos.</li>
          <li>Contribuir para a elaboração de especificações técnicas e documentos de concurso.</li>
          <li>Ser responsável pela impressão de documentos de concurso e pela disponibilização dos mesmos aos interessados.</li>
          <li>Colaborar na elaboração de relatórios semanais de avaliação de desempenho.</li>
          <li>Participar no processo de importação, incluindo o acompanhamento das Alfândegas e outras entidades reguladoras.</li>
          <li>Organizar o arquivo de documentos do sector e colaborar com auditorias internas e externas.</li>
          <li>Gerir a identificação e aquisição de matérias-primas, recursos, bens e serviços para as operações da empresa.</li>
          <li>Gerir orçamentos de compras e estratégias de pagamento.</li>
          <li>Criar RFQs e supervisionar ciclos de licitação competitiva.</li>
          <li>Negociar contratos com fornecedores, garantindo as melhores condições e preços.</li>
          <li>Monitorar o desempenho dos fornecedores, assegurando a entrega pontual e eficaz dos bens e serviços.</li>
          <li>Desenvolver e implementar políticas e estratégias de aquisição, contribuindo para a redução de custos e optimização de processos.</li>
          <li>Acompanhar métricas e KPIs relacionados com compras, propondo melhorias onde necessário.</li>
          <li>Trabalhar em estreita colaboração com outros departamentos para assegurar o alinhamento das necessidades de aquisição com as operações diárias.</li>
          <li>Elaborar relatórios e compilar dados para o Director de Compras.</li>

        </ul>
      </div>

      <div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Licenciatura em Gestão, Engenharia, ou áreas afins.</li>
          <li>Experiência mínima de 3 a 5 anos em funções semelhantes, preferencialmente no sector de Oil and Gas ou áreas relacionadas.</li>
          <li>Conhecimento profundo de processos de procurement, licitação e gestão de fornecedores.</li>
          <li>Experiência com sistemas de gestão de compras e análise de dados de aquisições.</li>
          <li>Capacidade de negociação e excelente comunicação com fornecedores e equipas internas.</li>
          <li>Familiaridade com regulamentações e normas aplicáveis ao sector.</li>
          <li>Fluência em português e inglês, tanto escrito como falado.</li>
        </ul>
      </div>

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Pacote salarial competitivo</li>
          <li>Plano de saúde</li>
          <li>Oportunidades de desenvolvimento profissional</li>
          <li>Ambiente de trabalho dinâmico e inovador</li>
          <li>Projectos desafiadores e impactantes</li>
        </ul>
      </div>

      <div className={style.applySection}>
        <Link to={`/candidaturaspersonal/${encodeURIComponent(titulo)}`}>
          <button className={style.applyButton}>Candidatar-se</button>
        </Link>
      </div>
    </div>
  );
}

export default ProcurementOfficer;
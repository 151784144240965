import { Link } from 'react-router-dom';
import styles from './QuemSomo.module.css';


function QuemSomos() {

    const handleLinkClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
       
    };

    return (
        <section className={styles.quemSomos}>
            <Link to='/sobre' className={styles.link}><h2 className={styles.title}>Quem Somos</h2></Link>
            <p className={styles.description}>
                Somos uma empresa de consultoria de RH e Negócios que se destaca pela abordagem inovadora
                e busca incansável pela excelência.
            </p>
            <p className={styles.description}>
                Uma <span className={styles.highlight}>equipa de profissionais com mais de 20 anos de experiência em Gestão de Recursos Humanos, Negócios e Projetos.</span>Fundimos expertise e sinergias para oferecer <span className={styles.highlight}>soluções disruptivas e inovadoras</span> às
                empresas.
            </p>
            <p className={styles.description}>
                A nossa <span className={styles.highlight}>missão</span> é fornecer as melhores soluções em <span className={styles.highlight}>captação, seleção e retenção de talentos, gestão de projectos, consultoria empresarial e auditoria de RH</span>, adaptadas às necessidades únicas de cada cliente. Estabelecemos parcerias com entidades reconhecidas <span className={styles.highlight}>nacional e internacionalmente</span> nas nossas áreas de especialização.
            </p>
            <Link to='/sobre' onClick={() => handleLinkClick()}>
                <button className={styles.button}>Ler mais</button>
            </Link>
        </section>
    );
}

export default QuemSomos;
import style from './KeyAccountManager.module.css';
import img from '../../../assets/ProjectManager.png'

function FieldeArmazem() {

  const imgg = img
  return (
    <div className={style.container}>
      <div className={style.jobHeader}>
        <div className={style.jobContent}>
          <h1 className={style.jobTitle}>Fiel de Armazem</h1>
          <div className={style.jobDescription}>
            <p>
              A Pathway Consulting recruta um(a) Gerente de Projectos para uma empresa do sector
              de Fast-Moving Consumer Goods (FMCG), com sede em Maputo. O(a) profissional será
              responsável pela gestão e coordenação de projectos, assegurando a entrega bem-sucedida
              das iniciativas dentro do prazo, orçamento e escopo definidos, mantendo altos padrões
              de qualidade e alinhamento com os objectivos estratégicos do negócio.
            </p>
          </div>
        </div>
        <div className={style.imageContainer}>
          <img className={style.ig} src={imgg} />
        </div>
      </div>

      <div className={style.requirementsSection}>
        <h2 className={style.sectionTitle}>Requisitos:</h2>
        <ul className={style.requirementsList}>
          <li>Formação superior em Gestão, Engenharia, Administração ou áreas afins</li>
          <li>Mínimo de 5 anos de experiência em gestão de projectos, preferencialmente no sector FMCG</li>
          <li>Certificação PMP ou similar é uma vantagem</li>
          <li>Experiência comprovada em metodologias de gestão de projectos (PMI, Agile, Scrum)</li>
          <li>Sólidos conhecimentos em ferramentas de gestão de projectos (MS Project, Jira, etc.)</li>
          <li>Experiência em gestão de orçamentos e recursos</li>
          <li>Excelentes habilidades de comunicação e liderança</li>
          <li>Capacidade de trabalhar com múltiplos stakeholders e gerir expectativas</li>
          <li>Fluência em Português e bons conhecimentos de Inglês</li>
        </ul>
      </div>

      <div className={style.responsibilitiesSection}>
        <h2 className={style.sectionTitle}>Responsabilidades:</h2>
        <ul className={style.responsibilitiesList}>
          <li>Liderar e coordenar projetos estratégicos da empresa</li>
          <li>Desenvolver e manter planos detalhados de projecto</li>
          <li>Gerir recursos, orçamentos e cronogramas</li>
          <li>Identificar e mitigar riscos do projecto</li>
          <li>Coordenar equipas multifuncionais</li>
          <li>Reportar status e métricas do projecto aos stakeholders</li>
          <li>Garantir a qualidade das entregas</li>
          <li>Facilitar reuniões e workshops de projecto</li>
        </ul>
      </div>

      <div className={style.offerSection}>
        <h2 className={style.sectionTitle}>Oferecemos:</h2>
        <ul className={style.offerList}>
          <li>Pacote salarial competitivo</li>
          <li>Plano de saúde</li>
          <li>Oportunidades de desenvolvimento profissional</li>
          <li>Ambiente de trabalho dinâmico e inovador</li>
          <li>Projectos desafiadores e impactantes</li>
        </ul>
      </div>

      <div className={style.applySection}>
        <button className={style.applyButton}>Candidatar-se</button>
      </div>
    </div>
  );
}

export default FieldeArmazem;